import React from 'react';
import PropTypes from 'prop-types';

import Carousel, { Modal, ModalGateway } from 'react-images';

import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';

import SurveyPage from 'pages/Survey/SurveyPage';
import Typography from 'components/Typography/Typography';
/* Redux */
import { appImageActions } from 'redux-utils/actions/index';
import { connect } from 'react-redux';
import { appSelector, appImageSelector } from 'redux-utils/selectors';
/* Utils */
import ls from 'lib/core/storageFactory';
import getDataLayer from 'utils/gtagManager';
import { isEqual } from 'lodash';
import { suiteData } from './data';

const client = ls.get('client');

const initialState = {
  downloadBulkOptions: {
    fileType: 'valid',
    includeDuplicates: false,
    stores: [],
    dateRange: {},
    selectAllStores: false,
    bulkStores: []
  },
  notificationSnack: {
    message: '',
    isOpen: false
  },
  date: {},
  store: {},
  session: {},
  modalOpen: false,
  imagesModal: {
    isOpen: false,
    currentIndex: 0
  },
  errors: {
    chooseDateRange: false
  },
  downloadMeta: {},
  allDownloadOptionsSelected: false,
  downloadBulkCheckBoxOptions: []
};

const customStyles = {
  view: base => ({
    ...base,
    maxHeight: '90vh',
    overflow: 'auto',
    '& img': {
      maxHeight: '90vh'
    }
  })
};


const generateRedirectLink = (link) => {
  if (link.includes('<client-id>')) {
    const client = ls.get('client');
    return link.replace('<client-id>', client);
  }
  return link;
}

class InfiSuite extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  render() {
    const {
      date,
      store,
      session,
      modalOpen,
      downloadBulkOptions,
      notificationSnack,
      imagesModal
    } = this.state;
    const {
      stores,
      sessions,
      photos,
      message,
      bulkStores,
      messages,
      isLoading,
      dashboardType,
      layoutCSS,
      photoAttributes
    } = this.props;

    return (
      <div style={{
        width: "100%",
        maxHeight: '100vh',
        overflowY: 'scroll !important'
      }}>
        <div style={{
          padding: '20px'
        }} className='product-grid'>
                {
                suiteData.map((product, index) => (
                    <div style={{
                      width: "100%",
                      height: '100%'
                    }}>
                      <div className="card-xyz-container">
                        <div className="card-xyz">
                          <img style={{
                            aspectRatio: '502/253',
                          }} src={product.screenshot || "https://placehold.co/600x400"} alt="Nature" />
                          <div className="card-xyz-content">
                            <h3>{product.title || ''}</h3>
                            <p>{product.description || ""}</p>
                            <div style={{
                              display: 'flex',
                              gap: '10px',
                              flexWrap: 'wrap'
                            }}>
                              {product.redirect_link && <a href={generateRedirectLink(product.redirect_link)} target='_blank' className="card-xyz-btn">Go to {product.title}</a>}
                              {product.doc_link && <button className="card-xyz-btn">Documentation</button>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
      </div>    
    );
  }
}

const mapStateToProp = state => ({
  appImageOptions: appImageSelector.appImageDownloadOptions(state),
  appImageDownloadOptionsState: appImageSelector.appImageDownloadOptionsState(
    state
  ),
  message: appImageSelector.getMessage(state),
  stores: appImageSelector.getStores(state),
  sessions: appImageSelector.getSessions(state),
  photos: appImageSelector.getPhotos(state),
  photoAttributes: appImageSelector.getPhotoAttributes(state),
  email: appSelector.getAuthState(state),
  bulkStores: appImageSelector.getBulkStores(state),
  messages: appImageSelector.getMessages(state),
  success: appImageSelector.getSuccess(state),
  errors: appImageSelector.getErrors(state),
  isLoading: appImageSelector.getLoading(state),
  layoutCSS: appSelector.getLayoutCSS(state),
  appImagesFilters: appImageSelector.getAppImagesFilters(state),
  appImageOptions: appImageSelector.appImageDownloadOptions(state),
  dashboardType: state.layoutStore.routes?.dashboardConfig?.version,
  sidebarFilters: appSelector.getSidebaFilters(state)
});

const mapDispatchToProps = dispatch => ({
  getStores: payload => dispatch(appImageActions.getStores(payload)),
  getStoreSessions: payload =>
    dispatch(appImageActions.getStoreSessions(payload)),
  getPhotos: payload => dispatch(appImageActions.getPhotos(payload)),
  downloadPhotos: payload => dispatch(appImageActions.downloadPhotos(payload)),
  getStoresDateRange: payload =>
    dispatch(appImageActions.getStoresRange(payload)),
  clearBulkStores: payload => {
    dispatch(appImageActions.clearBulkStores(payload));
  },
  initializeSuccessApis: payload => {
    dispatch(appImageActions.initializeSuccessApis(payload));
  },
  initializeFailureApis: payload => {
    dispatch(appImageActions.initializeFailureApis(payload));
  },
  dispatchGetAppImageDownloadImages: payload =>
    dispatch(appImageActions.getImageActionTypes(payload))
});

InfiSuite.propTypes = {
  getStores: PropTypes.func.isRequired,
  getStoreSessions: PropTypes.func.isRequired,
  downloadPhotos: PropTypes.func.isRequired,
  getPhotos: PropTypes.func.isRequired,
  photos: PropTypes.array,
  stores: PropTypes.array,
  sessions: PropTypes.array,
  message: PropTypes.string,
  bulkStores: PropTypes.array,
  getStoresDateRange: PropTypes.func.isRequired,
  clearBulkStores: PropTypes.func.isRequired,
  messages: PropTypes.object,
  success: PropTypes.object,
  errors: PropTypes.object,
  initializeSuccessApis: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  dashboardType: PropTypes.string.isRequired,
  layoutCSS: PropTypes.object,
  photoAttributes: PropTypes.object.isRequired
};
InfiSuite.defaultProps = {
  stores: [],
  sessions: [],
  photos: [],
  message: null,
  bulkStores: [],
  messages: {},
  success: {},
  errors: {},
  isLoading: false,
  layoutCSS: {}
};

export default connect(mapStateToProp, mapDispatchToProps)(InfiSuite);
