const types = {
  getShelfImage: {
    request: 'GET_SHELF_IMAGE_REQUEST',
    success: 'GET_SHELF_IMAGE_SUCCESS',
    failure: 'GET_SHELF_IMAGE_FAILURE',
    fromAPI: 'GET_SHELF_IMAGE_FROM_API',
    clear: 'SHELF_IMAGE_CLEAR'
  },
  getCurationStores: {
    request: 'GET_CURATION_STORES_REQUEST',
    success: 'GET_CURATION_STORES_SUCCESS',
    failure: 'GET_CURATION_STORES_FAILURE',
    fromAPI: 'GET_CURATION_STORES_FROM_API'
  },
  getCurationSessions: {
    request: 'GET_CURATION_SESSIONS_REQUEST',
    success: 'GET_CURATION_SESSIONS_SUCCESS',
    failure: 'GET_CURATION_SESSIONS_FAILURE',
    fromAPI: 'GET_CURATION_SESSIONS_FROM_API'
  },
  getStoreExplorerFilter: {
    request: 'GET_STORE_EXPLORER_FILTER_REQUEST',
    success: 'GET_STORE_EXPLORER_FILTER_SUCCESS',
    failure: 'GET_STORE_EXPLORER_FILTER_FAILURE',
    fromAPI: 'GET_STORE_EXPLORER_FILTER_FROM_API'
  },
  getDebugProblems: {
    request: 'GET_STORE_EXPLORER_DEBUG_PROBLEMS_REQUEST',
    success: 'GET_STORE_EXPLORER_DEBUG_PROBLEMS_SUCCESS',
    failure: 'GET_STORE_EXPLORER_DEBUG_PROBLEMS_FAILURE',
    fromAPI: 'GET_STORE_EXPLORER_DEBUG_PROBLEMS_FROM_API'
  },
  saveDashboardDebug: {
    request: 'SAVE_DASHBOARD_DEBUG_REQUEST',
    success: 'SAVE_DASHBOARD_DEBUG_SUCCESS',
    failure: 'SAVE_DASHBOARD_DEBUG_FAILURE',
    fromAPI: 'SAVE_DASHBOARD_DEBUG_FROM_API'
  },
  getDashboardDebug: {
    request: 'GET_DASHBOARD_DEBUG_REQUEST',
    success: 'GET_DASHBOARD_DEBUG_SUCCESS',
    failure: 'GET_DASHBOARD_DEBUG_FAILURE',
    fromAPI: 'GET_DASHBOARD_DEBUG_FROM_API'
  },
  updateProductFacing: {
    request: 'UPDATE_PRODUCT_FACING_REQUEST',
    success: 'UPDATE_PRODUCT_FACING_SUCCESS',
    failure: 'UPDATE_PRODUCT_FACING_FAILURE',
    fromAPI: 'UPDATE_PRODUCT_FACING_FROM_API',
    reset: 'UPDATE_PRODUCT_FACING_RESET'
  },
  fixProductFacing: {
    request: 'FIX_PRODUCT_FACING_REQUEST',
    success: 'FIX_PRODUCT_FACING_SUCCESS',
    failure: 'FIX_PRODUCT_FACING_FAILURE',
    fromAPI: 'FIX_PRODUCT_FACING_FROM_API',
    reset: 'FIX_PRODUCT_FACING_RESET'
  },
  tseCorrectionCompleted: {
    request: 'TSE_CORRECTION_COMPLETED_REQUEST',
    success: 'TSE_CORRECTION_COMPLETED_SUCCESS',
    failure: 'TSE_CORRECTION_COMPLETED_FAILURE',
    fromAPI: 'TSE_CORRECTION_COMPLETED_FROM_API',
  },
  productFilter: {
    request: 'PRODUCT_FILTER_REQUEST',
    success: 'PRODUCT_FILTER_SUCCESS',
    failure: 'PRODUCT_FILTER_FAILURE',
    fromAPI: 'PRODUCT_FILTER_FROM_API'
  }
};

export default types;
