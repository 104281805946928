import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { formatDate, getPreviousDate } from 'lib/core/dateUtils';

// store selectors
const getStoreExplorerStore = state => state.storeExplorerNew;

const getImageFetchingStatus = createSelector(
  [getStoreExplorerStore],
  ({ images: { isFetching } }) => isFetching
);

const getDebugProblems = createSelector(
  [getStoreExplorerStore],
  ({ debugProblems }) =>
    debugProblems
      ? debugProblems.map(problem => ({ label: problem, value: problem }))
      : []
);

const selectProductFilter = createSelector(
  [getStoreExplorerStore],
  ({ productFilter }) => productFilter
);

const selectFixProductFacing = createSelector(
  [getStoreExplorerStore],
  ({ fixProductFacing }) => fixProductFacing
);

const selectTseCorrectionCompleted = createSelector(
  [getStoreExplorerStore],
  ({ tseCorrectionCompleted }) => tseCorrectionCompleted
);

const getSaveDebug = createSelector(
  [getStoreExplorerStore],
  ({ saveDebug }) => saveDebug
);

const getProductFacing = createSelector(
  [getStoreExplorerStore],
  ({ productFacing }) => productFacing
);

const getReportData = createSelector(
  [getStoreExplorerStore],
  ({ reportData }) => reportData
);

const getShelfImages = createSelector(
  [getStoreExplorerStore],
  ({
    images: { list: imageList },
    variants: { list: variantList },
    excelReport
  }) => {
    if (!isEmpty(imageList) && !isEmpty(variantList)) {
      return {
        excelReport,
        imageList,
        variantList
      };
    }
    return {
      excelReport: false,
      imageList: null,
      variantList: null
    };
  }
);

const getFilterData = createSelector(
  getStoreExplorerStore,
  ({ filters }) => filters
);

const getFilterPreferences = (state, props) =>
  state.storeExplorerNew.preferences.filters[props.routeID];

const makeGetFilterPreferences = () =>
  createSelector([getFilterPreferences], prefs => prefs);

const getFilterList = state => state.storeExplorerNew.filters.list;

const makeGetFilterList = () =>
  createSelector([getFilterList], data => {
    const dataClone = { ...data.fl_date };
    if (dataClone) {
      dataClone.options = [
        {
          label: formatDate(getPreviousDate(1, 'days', false), true),
          value: 'yesterday',
          is_default: true
        },
        { label: 'Custom', value: 'custom', args: 'cus' }
      ];
    }
    return { ...data, fl_date: dataClone };
  });

const makeGetParamsValueList = () =>
  createSelector([getFilterPreferences], filterPrefs => {
    return {
      ...filterPrefs
    };
  });

const getFixProductFacingAPIStatus = createSelector(
  [getStoreExplorerStore],
  ({ fixProductFacing: { success, error } }) => {
    return {
      error,
      success
    };
  }
);

const getUpdateProductFacingAPIStatus = createSelector(
  [getStoreExplorerStore],
  ({ productFacing: { success, error } }) => {
    return {
      error,
      success
    };
  }
);

export {
  getFixProductFacingAPIStatus,
  getUpdateProductFacingAPIStatus,
  getSaveDebug,
  getProductFacing,
  getDebugProblems,
  getImageFetchingStatus,
  getShelfImages,
  getReportData,
  getFilterData,
  makeGetFilterList,
  makeGetFilterPreferences,
  makeGetParamsValueList,
  selectProductFilter,
  selectFixProductFacing,
  selectTseCorrectionCompleted
};
