/* eslint-disable no-lonely-if */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual, each, map } from 'lodash';
import { connect } from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
// redux-utils
import {
  appActions,
  storeExplorerActions,
  storeNewActions
} from 'redux-utils/actions/index';
import {
  appSelector,
  chartSelector,
  storeExplorerSelector,
  storeNewSelector,
  genericSESelector
} from 'redux-utils/selectors/index';
// core functions
import { getRouteID, getSimilarRoutes } from 'lib/utils/getActiveRoute';
import ls from 'lib/core/storageFactory';
import constants from 'models/constants';
// components
import Loader from 'components/Loader/Loader';
// views
import NoDataMessage from 'pages/GenericSE/NoDataMessage';
import SEFilterContainer from 'pages/StoreExplorer/SEFilterContainer';
import TestStoreExplorerPage from './TestStoreExplorerPage';

const client = ls.get('client');

const hasSessionFilter = filterList =>
  filterList.filter(el => el.value === 'fl_session').length > 0;

class TestStoreExplorerContainer extends Component {
  routeID = '';

  exportChartName = null;

  state = {
    tableData: null,
    showNotification: false
  };

  constructor(props) {
    super(props);
    const {
      location: { pathname }
    } = window;
    const {
      chartParamsValue,
      dispatchGetCurationStores,
      dispatchGetDebugProblems
    } = this.props;
    const routeID = getRouteID(pathname);
    this.routeID = routeID;
    const { fl_date: flDate } = chartParamsValue;
    dispatchGetDebugProblems({});
    if (flDate) {
      dispatchGetCurationStores({
        params: { date: flDate.fl_start_date.split('/').join('-') },
        // params: { date: '14-03-2020' },
        menuModel: getSimilarRoutes('store-explorer')
      });
    }
  }

  componentDidMount = () => {
    this.props.clearTestSessionData();
  };

  componentWillReceiveProps = nextProps => {
    const {
      shelfData: { images, variants },
      chartParamsValue,
      filters,
      defaultFilters,
      productFacing,
      fixProductFacingState,
      tseCorrectionCompleted
    } = nextProps;

    const {
      dispatchGetImages,
      dispatchGetCurationStores,
      dispatchGetCurationSessions,
      dispatchGetDashboardDebug,
      dispatchProductFilter
    } = this.props;
    if (variants && images) {
      this.computeTableData(variants);
    }
    if (!isEmpty(chartParamsValue) && filters) {
      if (!isEqual(this.props.filters, filters) && !filters.fl_store) {
        const { fl_date: flDate, fl_store: flStore } = chartParamsValue;
        if (flDate && !isEmpty(flStore)) {
          dispatchGetCurationStores({
            // params: { date: '14-03-2020' },
            params: { date: flDate.fl_start_date.split('/').join('-') },
            menuModel: getSimilarRoutes('store-explorer')
          });
        }
      }
    }
    if (!this.state.showNotification) {
      if (
        productFacing.success ||
        productFacing.error ||
        fixProductFacingState.success ||
        fixProductFacingState.error
      ) {
        this.setState({ showNotification: true });
      }
    }
    if (
      !isEmpty(chartParamsValue) &&
      !isEqual(this.props.chartParamsValue, chartParamsValue)
    ) {
      const {
        fl_date: flDate,
        fl_category: flCategory,
        fl_placement: flPlacement,
        fl_type: flType,
        fl_store: flStore,
        fl_session: flSession
      } = chartParamsValue;
      if ((flDate && flCategory && flType) || flDate) {
        if (hasSessionFilter(defaultFilters)) {
          if (!isEmpty(flStore)) {
            if (this.props.chartParamsValue.fl_session) {
              if (
                this.props.chartParamsValue.fl_session.value === flSession.value
              ) {
                dispatchGetCurationSessions({
                  params: {
                    date: flDate.fl_start_date.split('/').join('-'),
                    fl_store: flStore.value
                  },
                  menuModel: getSimilarRoutes('store-explorer')
                });
              }
            } else {
              dispatchGetCurationSessions({
                params: {
                  date: flDate.fl_start_date.split('/').join('-'),
                  fl_store: flStore.value
                },
                menuModel: getSimilarRoutes('store-explorer')
              });
            }
            if (!isEmpty(flSession)) {
              if (flStore.value) {
                dispatchGetDashboardDebug({
                  params: {
                    // fl_date: '14/03/2020',
                    date: flDate.fl_start_date.split('/').join('-'),
                    category: flCategory.value,
                    store_id: flStore.value
                  }
                });
                dispatchProductFilter({
                  params: {
                    fl_category: flCategory.value
                  }
                });
              }
              // dispatchGetImages({
              //   params: {
              //     // fl_date: '14/03/2020',
              //     test: true,
              //     fl_date: flDate.fl_start_date,
              //     fl_placement: flPlacement ? flPlacement.value : undefined,
              //     fl_category: flCategory.value,
              //     fl_type: flType ? flType.value : undefined,
              //     fl_store: flStore.value,
              //     fl_session: flSession.value
              //   },
              //   cacheRequest: false
              // });
              return;
            }
          }
        } else {
          if (flStore && flCategory) {
            if (flStore.value) {
              dispatchGetDashboardDebug({
                params: {
                  // fl_date: '14/03/2020',
                  date: flDate.fl_start_date.split('/').join('-'),
                  category: flCategory.value,
                  store_id: flStore.value
                }
              });
              dispatchProductFilter({
                params: {
                  fl_category: flCategory.value
                }
              });
            }
            // dispatchGetImages({
            //   params: {
            //     // fl_date: '14/03/2020',
            //     test: true,
            //     fl_date: flDate.fl_start_date,
            //     fl_placement: flPlacement ? flPlacement.value : undefined,
            //     fl_category: flCategory.value,
            //     fl_type: flType ? flType.value : undefined,
            //     fl_store: flStore.value
            //   },
            //   cacheRequest: false
            // });
          }
        }
      }
      if (flDate && isEmpty(flStore)) {
        if (!isEqual(this.props.chartParamsValue.fl_date, flDate)) {
          dispatchGetCurationStores({
            // params: { date: '14-03-2020' },
            params: { date: flDate.fl_start_date.split('/').join('-') },
            menuModel: getSimilarRoutes('store-explorer')
          });
        }
      }
    }
  };

  computeTableData = data => {
    const columns = [];
    const ignoreKeys = [
      'color',
      'min',
      'max',
      'delta',
      'цвет',
      'Мин. цена',
      'Макс. цена',
      'Отличие цены'
    ];
    const tableData = {
      datasets: [],
      columns: []
    };
    each(data[0].details, item => {
      map(item, (_v, key) => {
        if (!ignoreKeys.includes(key)) {
          columns.push(key);
          tableData.columns.push({
            label: key.split('_').join(' '),
            dataKey: key
          });
        }
      });
    });
    each(data, el => {
      const { detections: bnBox, details, id } = el;
      const tempItem = { bnBox, variantId: id };
      map(details, item => {
        const itemKeys = Object.keys(item);
        const arrays = [itemKeys, columns];
        const result = arrays
          .sort((a, b) => {
            return a.length - b.length;
          })
          .shift()
          .filter(v => {
            return arrays.every(a => {
              return a.indexOf(v) !== -1;
            });
          });
        if (itemKeys.includes('color')) {
          // eslint-disable-next-line prefer-destructuring
          tempItem[result[0]] = (
            <p style={{ color: item.color, margin: 0 }}>{item[result[0]]}</p>
          );
        } else if (
          itemKeys.includes(
            client.includes('kcc-ru') ? 'Соответствие цены' : 'price_compliance'
          )
        ) {
          tempItem[result[0]] = item;
        } else {
          // eslint-disable-next-line prefer-destructuring
          tempItem[result[0]] = item[result[0]];
        }
      });
      tableData.datasets.push(tempItem);
    });
    // concat other brand to last of array
    const position =
      tableData.datasets.map(e => e.brand).indexOf('Others') ||
      tableData.datasets.map(e => e['Категория']).indexOf('Другое');
    tableData.datasets = tableData.datasets.concat(
      tableData.datasets.splice(position, 1)
    );
    this.setState({ tableData });
  };

  /**
   * @method
   * @description Handles the filter change event
   * @param {object} val - Changed value
   * @param {string} key - value key
   * @return {undefined}
   */
  handleFilterChange = (val, key) => {
    if (!isEmpty(val)) {
      const { routeID } = this;
      const {
        chartParamsValue,
        dispatchFilterChange,
        dispatchGetCurationStores,
        dispatchGetCurationSessions,
        defaultFilters
      } = this.props;
      if (hasSessionFilter(defaultFilters)) {
        const value = key !== undefined ? { [key]: val } : val;
        dispatchFilterChange({
          routeID,
          value
        });
        if (key === 'fl_date') {
          if (chartParamsValue.fl_category) {
            this.fetchChartList(this.props, chartParamsValue.fl_category.value);
            dispatchGetCurationStores({
              params: { date: val.fl_start_date.split('/').join('-') },
              // params: { date: '14-03-2020' },
              menuModel: getSimilarRoutes('store-explorer')
            });
          }
        } else if (chartParamsValue.fl_store || value.fl_store) {
          dispatchGetCurationSessions({
            params: {
              date: chartParamsValue.fl_date.fl_start_date.split('/').join('-'),
              fl_store: chartParamsValue.fl_store.value
            },
            menuModel: getSimilarRoutes('store-explorer')
          });
        }
      } else {
        const value = key !== undefined ? { [key]: val } : val;
        dispatchFilterChange({
          routeID,
          value
        });
        if (key === 'fl_date') {
          dispatchGetCurationStores({
            params: { date: val.fl_start_date.split('/').join('-') },
            // params: { date: '14-03-2020' },
            menuModel: getSimilarRoutes('store-explorer')
          });
        }
      }
    }
  };

  saveDashboardDebug = val => {
    const { chartParamsValue, dispatchSaveDashboardDebug } = this.props;
    const params = {
      ...val,
      date: chartParamsValue.fl_date.fl_start_date.split('/').join('-'),
      // date: '14/03/2020',
      store_id: chartParamsValue.fl_store.value,
      category: chartParamsValue.fl_category.value
    };
    dispatchSaveDashboardDebug({ params });
  };

  handleFixProductFacing = params => {
    const { dispatchFixProductFacing, tseWorkflow, shelfData } = this.props;

    let data = params

    const paramsObj = { 
      "session_id": shelfData?.session_data?.[0] || null,
      "photo_id": [],
      "updated_data": {
        "facing_ids": [],
        "is_own": true,
        "brand": null,
        "variant": null,
        "sku": null,
      }
    }

    if (tseWorkflow === 'v2') {
      const tempParam = data[0]

      paramsObj.updated_data.is_own = tempParam.is_own
      paramsObj.updated_data.brand = tempParam.brand || null
      paramsObj.updated_data.variant = tempParam.variant || null
      paramsObj.updated_data.sku = tempParam.sku || null
      
      data.forEach(res => {
        if (!paramsObj.photo_id.includes(res.photo_id)) {
          paramsObj.photo_id.push(res.photo_id)
        }
        if (!paramsObj.updated_data.facing_ids.includes(res.facing_id)) {
          paramsObj.updated_data.facing_ids.push(res.facing_id)
        }
      })
    }
    
    dispatchFixProductFacing({ params: tseWorkflow === 'v2' ? paramsObj: data, tseWorkflow });
    // if (p.payload && p.payload.length > 0) {
    //   dispatchFixProductFacing({ params: p.payload });
    // } else {
    //   const payload = {
    //     ...(p.params && { ...p.params }),
    //     date: chartParamsValue.fl_date.fl_start_date,
    //     store_id: chartParamsValue.fl_store.value,
    //     fl_category: chartParamsValue.fl_category.value
    //   };
    //   dispatchFixProductFacing({ params: payload });
    // }
  };

  handleTseCorrectionCompleted = () => {
    const { dispatchTseCorrectionsCompleted, shelfData } = this.props

    dispatchTseCorrectionsCompleted({ params: {
      session_id: shelfData?.session_data?.[0]
    }})

  }

  handleNotificationClose = () => {
    this.setState({ showNotification: false }, () => {
      this.props.resetProductFacingDetails();
      this.props.resetFixProductFacingDetails();
    });
  };

  render() {
    const { tableData, showNotification } = this.state;
    const {
      loadingState,
      filters,
      debugProblems,
      productFilter,
      fixProductFacingState,
      saveDebug,
      useDisplayTag,
      dispatchUpdateProductFacing,
      dashboardType,
      tseWorkflow,
      layoutCSS,
      productFacing,
      shelfData: { images, sessionId, stickerBoxes, variants },
      shelfData,
      sessionInfo,
      fixProductFacingAPIStatus,
      updateProductFacingAPIStatus,
      clearTestSessionData,
      isFetchingSessionDataSuccessful,
      areFiltersSet,
      bottomUpFilters,
      sidebarFilters
    } = this.props;

    const doesLatestFilterHaveValue = !!bottomUpFilters[
      bottomUpFilters.incomingFilter
    ]?.options.length;

    return (
      <>
        {/* <SEFilterContainer
          routeID={this.routeID}
          handleFilterChange={this.handleFilterChange}
        /> */}
        {/* {dashboardType !== 'v2' && (
          <SEFilterContainer
            routeID={this.routeID}
            handleFilterChange={this.handleFilterChange}
          />
        )} */}

        {!doesLatestFilterHaveValue ? (
          <NoDataMessage shared={true} message={'No Filters Available'} />
        ) : loadingState || !areFiltersSet ? (
          <>
            <Loader plain circular centered color="secondary" inline />
          </>
        ) : images && tableData ? (
          <div
            style={{
              height: '83vh',
              overflow: 'auto',
              overflowX: 'hidden',
              maxWidth: constants.mainWindowWidth
            }}
          >
            <TestStoreExplorerPage
              useExport={false}
              useDisplayTag={useDisplayTag}
              photosList={images}
              stickerBoxes={stickerBoxes}
              debugProblems={debugProblems}
              slicers={{}}
              tseWorkflow={tseWorkflow}
              sessionId={sessionId}
              productFilter={productFilter}
              fixProductFacingState={fixProductFacingState}
              saveDebug={saveDebug}
              updateProductFacing={dispatchUpdateProductFacing}
              fixProductFacing={p => this.handleFixProductFacing(p)}
              handleDebugSave={this.saveDashboardDebug}
              tableData={tableData}
              handleDataDownload={this.handleDataDownload}
              primaryButtonStyles={layoutCSS?.theme?.primaryButton}
              cardStyles={layoutCSS?.theme?.card}
              dashboardPanelStyles={layoutCSS?.theme?.dashboardPanel}
              tooltipStyles={layoutCSS?.theme?.tooltip}
              dropdownStyles={layoutCSS?.theme?.dropdown}
              dropdownTitleStyles={layoutCSS?.theme?.dropdownTitle}
              variantList={variants}
              dashboardType={dashboardType}
              shelfData={shelfData}
              saveFacings={this.handleFixProductFacing}
              handleTseCorrectionCompleted={this.handleTseCorrectionCompleted}
              sessionInfo={sessionInfo}
              sessionName={
                bottomUpFilters['fl_store'].hashedOptions[
                  sidebarFilters['fl_store']
                ].label
              }
              sessionDate={
                bottomUpFilters['fl_dropdown_date'].hashedOptions[
                  sidebarFilters['fl_dropdown_date']
                ].label
              }
              fixProductFacingAPIStatus={fixProductFacingAPIStatus}
              updateProductFacingAPIStatus={updateProductFacingAPIStatus}
              dispatchUpdateProductFacing={dispatchUpdateProductFacing}
              clearTestSessionData={clearTestSessionData}
            />
          </div>
        ) : (
          <NoDataMessage
            otherMessage={
              isFetchingSessionDataSuccessful &&
              !shelfData.images?.length &&
              !shelfData.variants?.length
                ? 'Please navigate to App Images'
                : ''
            }
          />
        )}
        <Snackbar
          open={showNotification}
          autoHideDuration={3000}
          onClose={this.handleNotificationClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={this.handleNotificationClose}
            severity={productFacing.error ? 'error' : 'success'}
          >
            {productFacing.data.message}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

TestStoreExplorerContainer.propTypes = {
  dispatchGetImages: PropTypes.func.isRequired,
  dashboardType: PropTypes.string.isRequired,
  tseWorkflow: PropTypes.string.isRequired,
  layoutCSS: PropTypes.object
};

TestStoreExplorerContainer.defaultProps = {
  layoutCSS: {}
};

/*
  Connect redux store state to props so that you can access the state
  from the scope of the component's props
*/
const makeMapStateToProps = () => {
  const {
    location: { pathname }
  } = window;
  const routeID = getRouteID(pathname);
  const getParamsValueList = chartSelector.makeGetParamsValueList();
  const getFilterList = appSelector.makeGetFilterList();
  const getDefaultFilters = appSelector.makeGetDefaultFilters();

  const mapStateToProps = (state, props) => ({
    chartParamsValue: getParamsValueList(state, { routeID }),
    loadingState: storeExplorerSelector.getImageFetchingStatus(state),
    debugProblems: storeNewSelector.getDebugProblems(state),
    saveDebug: storeNewSelector.getSaveDebug(state),
    productFacing: storeNewSelector.getProductFacing(state),
    productFilter: storeNewSelector.selectProductFilter(state),
    tseCorrectionCompleted: storeNewSelector.selectTseCorrectionCompleted(state),
    fixProductFacingState: storeNewSelector.selectFixProductFacing(state),
    fixProductFacingAPIStatus: storeNewSelector.getFixProductFacingAPIStatus(
      state
    ),
    updateProductFacingAPIStatus: storeNewSelector.getUpdateProductFacingAPIStatus(
      state
    ),
    useDisplayTag: appSelector.getClientPreferences(state)
      .store_explorer_display_tag,
    filters: getFilterList(state),
    shelfData: storeExplorerSelector.getShelfImages(state),
    defaultFilters: getDefaultFilters(state, props),
    dashboardType: state.layoutStore.routes?.dashboardConfig?.version,
    tseWorkflow: appSelector.getTseWorkflow(state),
    layoutCSS: appSelector.getLayoutCSS(state),
    sessionInfo: appSelector.getSessionInfo(state),
    isFetchingSessionDataSuccessful: storeExplorerSelector.isFetchingSessionDataSuccessful(
      state
    ),
    areFiltersSet: appSelector.getAreFiltersSet(state),
    sidebarFilters: appSelector.getSidebaFilters(state),
    bottomUpFilters: genericSESelector.getBottomUpFilter(state)
  });
  return mapStateToProps;
};

/*
  Connect dispatch methods to props so that you can call the methods
  from the scope of the component's props
*/
const mapDispatchToProps = dispatch => ({
  // /api/v1/get_store_photos/
  dispatchGetImages: payload =>
    dispatch(storeExplorerActions.getShelfImageAction(payload)),
  dispatchGetDebugProblems: payload =>
    dispatch(storeNewActions.getDebugProblemsAction(payload)),
  dispatchUpdateProductFacing: payload =>
    dispatch(storeNewActions.updateProductFacingAction(payload)),
  dispatchFixProductFacing: payload =>
    dispatch(storeNewActions.fixProductFacingAction(payload)),

  dispatchTseCorrectionsCompleted: payload =>
    dispatch(storeNewActions.tseCorrectionCompletedAction(payload)),
  // /api/v1/dashboard_debug/
  dispatchGetDashboardDebug: payload =>
    dispatch(storeNewActions.getDashboardDebugAction(payload)),
  // /api/v1/product_filter/
  dispatchProductFilter: payload =>
    dispatch(storeNewActions.productFilterAction(payload)),
  dispatchSaveDashboardDebug: payload =>
    dispatch(storeNewActions.saveDashboardDebugAction(payload)),
  dispatchGetCurationStores: payload =>
    dispatch(
      storeExplorerActions.getCurationStoresAction({
        ...payload,
        cacheRequest: false
      })
    ),
  // /api/v1/get_stores_date/
  dispatchGetCurationSessions: payload =>
    dispatch(
      storeExplorerActions.getCurationSessionsAction({
        ...payload,
        cacheRequest: false
      })
    ),
  dispatchFilterChange: payload =>
    dispatch(appActions.filterChangeAction(payload)),
  resetProductFacingDetails: () =>
    dispatch(storeNewActions.resetProductFacingDetails()),
  resetFixProductFacingDetails: () =>
    dispatch(storeNewActions.resetFixProductFacingDetails()),
  clearTestSessionData: () => dispatch(storeExplorerActions.clearSessionData())
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(TestStoreExplorerContainer);
