import { createSelector } from '@reduxjs/toolkit';
// helpers
import { computeFilters, generateRoute, generateRightMenu } from './helpers';

// store selectors
const getAppStore = state => state.appStore;
const getAuthStore = state => state.authStore;
const getChartStore = state => state.chartStore;
const getLayoutStore = state => state.layoutStore;
const getNetworkStore = state => state.network;
const getSurveyDetails = state => state.appStore;

// memoized selector creators
const getAuthState = createSelector([getAuthStore], state => state);

const getActiveRoute = createSelector(
  [getAppStore],
  state => state.activeRouteID
);

const getLayout = createSelector(
  [getLayoutStore],
  ({ routes: list }) => list.layout
);

const getTseWorkflow = createSelector(
  [getLayoutStore],
  ({ routes: data }) => data?.dashboardConfig?.tse_workflow_version || 'v1'
);

const getLayoutNetworkState = createSelector(
  [getLayoutStore],
  ({ routes: list }) => list.isFetching
);

const getAnalyticsRedirect = createSelector(
  [getAppStore],
  ({ analyticsRedirect }) => analyticsRedirect
);

const getPreviousRoute = createSelector(
  [getAppStore],
  state => state.previousRouteID
);

const getClientPreferences = createSelector(
  [getAppStore],
  state => state.clientPreferences
);

const getSidebaFilters = createSelector(
  [getAppStore],
  state => state.sidebarFilters
);

const getMenuStructure = createSelector([getLayoutStore], ({ routes: list }) =>
  generateRoute(list.layout)
);

const getRightMenu = createSelector([getLayoutStore], ({ routes: list }) =>
  generateRightMenu(list.layout)
);

const getNetworkState = createSelector([getNetworkStore], state => state);

const getDefaultFilters = (state, props) =>
  state.layoutStore.routes.layout.filter(e => e.routeID === props.routeID);

const makeGetDefaultFilters = () =>
  createSelector([getDefaultFilters], appFilter =>
    appFilter !== undefined && appFilter.length !== 0
      ? appFilter[0].defaults
      : []
  );

const getFilterPreferences = (state, props) =>
  state.appStore.preferences.filters[props.routeID];

const getAllFilterPreferences = state => state.appStore.preferences.filters;

const makeGetAllFilterPreferences = () =>
  createSelector([getAllFilterPreferences], prefs => prefs);

const makeGetFilterPreferences = () =>
  createSelector([getFilterPreferences], prefs => prefs);

const getFilterList = state => state.filterStore;

const makeGetFilterList = () =>
  createSelector([getFilterList], data => computeFilters(data));

const getLoadingState = createSelector(
  [getAppStore, getChartStore],
  (app, chart) => app.isFetching || chart.isFetching
);

const getLayoutCSS = createSelector(
  [getLayoutStore],
  state => state.routes?.dashboardConfig?.css
);

const getLogoutRedirectionData = createSelector(
  [getAuthStore],
  state => state.logoutRedirection
);

const getSessionInfo = createSelector([getAppStore], state => {
  const currentFilters = state.preferences.filters[state.activeRouteID];
  return {
    storeName: currentFilters?.fl_store?.label,
    sessionData: currentFilters?.fl_date?.label,
    isDisplay: currentFilters?.fl_placement?.label.toLowerCase() === 'display'
  };
});

const getAreFiltersSet = createSelector(
  [getAppStore],
  ({ filterOrder }) => filterOrder
);

const getSurveyDetailsSelector = createSelector(
  [getSurveyDetails],
  state => state.surveyDetailsData
);

export {
  getAnalyticsRedirect,
  getClientPreferences,
  getActiveRoute,
  getPreviousRoute,
  getAuthState,
  getRightMenu,
  makeGetDefaultFilters,
  makeGetFilterList,
  makeGetFilterPreferences,
  makeGetAllFilterPreferences,
  getFilterList,
  getLayout,
  getTseWorkflow,
  getLayoutNetworkState,
  getSessionInfo,
  getLoadingState,
  getLogoutRedirectionData,
  getMenuStructure,
  getNetworkState,
  getLayoutCSS,
  getSidebaFilters,
  getAreFiltersSet,
  getSurveyDetailsSelector
};
