const apiModel = {
  loginApi: 'apv1_login',
  logoutApi: 'apv1_logout',
  getAnalyticsRedirect: 'apv1_analytics_redirect',
  getChartList: 'apv1_get_chart_list',
  getChartData: 'apv1_get_chart_data',
  getClientPreferences: 'apv1_get_client_preferences',
  getStoreCount: 'apv1_get_store_count',
  downloadChartData: 'apv1_download_chart_data',
  getCurationStores: 'apv1_get_curation_stores',
  getCurationSessions: 'apv1_get_curation_sessions',
  getLayoutList: 'apv1_get_layout_list',
  getFilterList: 'apv1_get_filter_list',
  getAIAccuracySessionFilter: 'apv1_get_ai_session_filter_list',
  getShelfImage: 'apv1_get_shelf_photos',
  getDashBoardCharts: 'apv1_gwt_dashboard_chart_list',
  getUserPrefs: 'apv1_get_user_prefs',
  saveUserPrefs: 'apv1_post_user_prefs',
  getUserProfile: 'apv1_get_user_profile',
  pinToDashboard: 'api_v1_pin_chart',
  unPinFromDashboard: 'api_v1_unpin_chart',
  getStoreExplorerFilter: 'api_v1_get_store_explorer_filter',
  getSEFilter: 'api_v1_get_store_explorer_filters',
  dataDownload: 'apv1_download_data',
  getDebugProblems: 'apv1_get_debug_problems',
  saveDashboardDebug: 'apv1_save_dashboard_debug',
  getDashboardDebug: 'apv1_get_dashboard_debug',
  updateProductFacing: 'apv1_update_product_facing',
  getSubPanel: 'apv1_get_sub_panel',
  getSubPanelPageData: 'apv1_get_sub_panel_page_data',
  uploadPackshot: 'apv1_sub_panel_packshot_upload',
  fixProductFacing: 'apv1_fix_product_facing',
  tseCorrectionCompleted: 'apv2_tse_correction_completed',
  productFilter: 'apv1_product_filter'
};

export default apiModel;
