export const suiteData = [
    {
      "title": "Curation",
      "description": "A data curation tool used to refine and prepare datasets for AI model training, ensuring high-quality inputs for better accuracy.",
      "doc_link": "",
      "screenshot": "https://res.cloudinary.com/dbmrecw1s/image/upload/q_auto,f_auto/v1740732282/c0pffpnevm3yepydb4fu.png",
      "redirect_link": "https://curation.infilect.com/curation/clients"
    },
    {
      "title": "InfiBrain",
      "description": "A catalog management & self-learning AI tool that identifies patterns, manages EAN codes, and creates structured catalogs for AI processing and recognition.",
      "doc_link": "",
      "screenshot": "https://res.cloudinary.com/dbmrecw1s/image/upload/q_auto,f_auto/v1740732282/ibbqivbrk1kpofythdr6.png",
      "redirect_link": "https://infibrain.infilect.com/home/"
    },
    {
      "title": "Schema Management Tool",
      "description": "Validates catalog schemas generated by InfiBrain, ensuring data integrity, compliance, and consistency in AI training",
      "doc_link": "",
      "screenshot": "https://res.cloudinary.com/dbmrecw1s/image/upload/q_auto,f_auto/v1740732282/r9heess4so3rxk0x7hte.png",
      "redirect_link": "https://<client-id>.infibrain.ai/admin/"
    },
    {
      "title": "SEAM",
      "description": "Establishes ground truth for AI models and evaluates their accuracy across defined sessions to improve performance.",
      "doc_link": "",
      "screenshot": "https://res.cloudinary.com/dbmrecw1s/image/upload/q_auto,f_auto/v1740732282/ovh02v9hp5dkamicizkr.png",
      "redirect_link": "https://<client-id>.infiviz.ai/seam/app/sessions"
    },
    {
      "title": "MLD",
      "description": " An internal debugging tool used by the AI team to conduct sanity checks and troubleshoot AI models before deployment to production.",
      "doc_link": "",
      "screenshot": "https://res.cloudinary.com/dbmrecw1s/image/upload/q_auto,f_auto/v1740732281/rxoqeomcfpbjgjn6z267.png",
      "redirect_link": "https://mldebugging.infilect.com/"
    },
    {
      "title": "Data Management Tool",
      "description": "Manages and maintains master data configurations for clients, ensuring structured and accurate data setup for seamless operations.",
      "doc_link": "",
      "screenshot": "https://res.cloudinary.com/dbmrecw1s/image/upload/q_auto,f_auto/v1740732282/wwgdfeyc96gnoosdjpnv.png",
      "redirect_link": "https://infiviz.info/dashboard"
    }
]
  