/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
// library to set properties for components
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, isObject, isArray } from 'lodash';
import styled from 'styled-components';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/core
import Tooltip from '@material-ui/core/Tooltip';
// custom select component for react
import Select from 'react-select';
// core components
import Typography from 'components/Typography/Typography';
import Notification from 'components/Notification/Notification';
import Duplicate from 'assets/svg/copy_black.svg';
import InfoIcon from 'assets/svg/info.svg';
// styles
import { customStyles, dropDownStyles } from './styles';

const StyledSelect = styled(Select)`
  && {
    font-size: ${props => `${props.fontSize} !important`};
    color: ${props => `${props.color} !important`};
    border-color: ${props => `${props.borderColor} !important`};
    background-color: ${props => `${props.bgColor} !important`};
  }
  && div[class*='singleValue'] {
    color: ${props => `${props.color} !important`};
  }
  && div[class*='control'] {
    border-color: ${props => `${props.borderColor} !important`};
    background-color: ${props => `${props.bgColor} !important`};
  }
  && div[class*='MenuList'] {
    background-color: ${props => `${props.bgColor} !important`};
  }
  && div[class*='option'] {
    color: ${props => `${props.color} !important`};
    background-color: ${props => `${props.bgColor} !important`};
  }
`;

/**
 * @class Dropdown
 * @hideconstructor
 * @description Customized stateless select dropdown component
 */
const Dropdown = ({
  options,
  button: isButton,
  handleValueChange,
  value: currentValue,
  width,
  title,
  isMulti,
  titleColor,
  classes,
  useMenuPortalTarget,
  routeID,
  type,
  placeholder,
  dropdownTitleStyles,
  dropdownStyles,
  isDisabled,
  dashboardType,
  isLoading,
  showCopy,
  showInfo
}) => {
  const [notificationSnack, setNotificationSnack] = useState({});
  return (
    <div style={{ width: "100%", padding: '0px 30px'}} className={classes.dropDown}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '2px', }}>
        {title ? (
          <Typography
            className={classes.label}
            color={titleColor}
            weight={400}
            size={14}
            filterTitle
            textColor={dropdownTitleStyles?.color}
            fontSize={dropdownTitleStyles?.fontSize}
          >
            {title}
          </Typography>
        ) : null}
        {showInfo && (
          <Tooltip
            classes={{
              tooltip: classes.tooltip
            }}
            title={currentValue?.label || 'NA'}
            interactive
          >
            <img
              alt="info"
              src={InfoIcon}
              style={{
                width: '25px',
                color: 'black',
                marginLeft: 'auto',
                marginTop: '5px',
                marginBottom: '8px'
              }}
            />
          </Tooltip>
        )}
        {showCopy && (
          <img
            alt="copy-icon"
            src={Duplicate}
            style={{
              width: '12px',
              color: 'black',
              marginBottom: '8px',
              cursor: currentValue.label ? 'pointer' : 'no-drop'
            }}
            onClick={() => {
              if (currentValue.label) {
                navigator.clipboard.writeText(currentValue.label);
                setNotificationSnack({
                  isOpen: true,
                  message: `Copied ${title} to clipboard.`
                });
              }
            }}
          />
        )}
      </div>
      <StyledSelect
        color={dropdownStyles?.textColor}
        bgColor={dropdownStyles?.backgroundColor}
        fontSize={dropdownStyles?.fontSize}
        borderColor={dropdownStyles?.borderColor}
        className={classes.dropdownMenu}
        id={`${routeID}-${type}`}
        isMulti={isMulti}
        isDisabled={isDisabled}
        styles={customStyles(isButton, width, dashboardType)}
        menuPortalTarget={useMenuPortalTarget && document.querySelector('body')}
        placeholder={isLoading ? '' : placeholder}
        dashboardType={dashboardType}
        isLoading={isLoading}
        onChange={updatedValue => {
          if (isMulti) {
            let refinedValue = updatedValue ? [...updatedValue] : [];
            if (updatedValue !== null) {
              if (updatedValue.length > 1) {
                let isAllInCurrentValue = 0;
                let isAllInUpdatedValue = 0;
                if (isObject(currentValue)) {
                  isAllInCurrentValue = currentValue.value === 'all';
                }
                if (isArray(currentValue)) {
                  isAllInCurrentValue =
                    currentValue.filter(el => el.value === 'all').length > 0;
                }
                if (isObject(updatedValue)) {
                  isAllInUpdatedValue = updatedValue.value === 'all';
                }
                if (isArray(updatedValue)) {
                  isAllInUpdatedValue =
                    updatedValue.filter(el => el.value === 'all').length > 0;
                }
                if (isAllInCurrentValue && isAllInUpdatedValue) {
                  const index = refinedValue.findIndex(
                    el => el.value === 'all'
                  );
                  if (index > -1) {
                    refinedValue.splice(index, 1);
                  }
                }
                if (!isAllInCurrentValue && isAllInUpdatedValue) {
                  [refinedValue] = refinedValue.filter(
                    el => el.value === 'all'
                  );
                }
              }
            }
            handleValueChange(refinedValue);
          } else if (
            updatedValue.value !== currentValue.value ||
            updatedValue.value === 'custom'
          ) {
            handleValueChange(updatedValue);
          }
        }}
        value={isEmpty(currentValue) ? null : currentValue}
        options={options}
      />
      <Notification
        message={notificationSnack.message}
        isOpen={notificationSnack.isOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        handleClose={() => setNotificationSnack({ isOpen: false, message: '' })}
      />
    </div>
  );
};

// component properties
Dropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  /**
   * @type {string}
   * @description - Title of the dropdown
   */
  title: PropTypes.string,
  /**
   * @type {string}
   * @description - Dropdown title color
   */
  titleColor: PropTypes.string,
  /**
   * @type {array}
   * @description - Whether dropdown is disabled
   */
  isDisabled: PropTypes.bool,
  /**
   * @type {array}
   * @description - Whether to apply button styles
   */
  isMulti: PropTypes.bool,
  /**
   * @type {array}
   * @description - Whether to apply button styles
   */
  button: PropTypes.bool,
  /**
   * @callback
   * @description - Callback function to handle the dropdown change
   */
  handleValueChange: PropTypes.func.isRequired,
  /**
   * @type {array}
   * @description - Dropdown options
   */
  options: PropTypes.array.isRequired,
  /**
   * @type {object}
   * @description - Dropdown value
   */
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /**
   * @type {number}
   * @description - Width of the dropdown
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * @type {bool}
   * @description - Whether to use menuPortalTarget prop
   */
  useMenuPortalTarget: PropTypes.bool,
  routeID: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  dropdownTitleStyles: PropTypes.object,
  dropdownStyles: PropTypes.object,
  showInfo: PropTypes.bool
};

Dropdown.defaultProps = {
  button: false,
  isMulti: false,
  width: 140,
  value: {},
  title: undefined,
  isDisabled: false,
  titleColor: 'light25',
  useMenuPortalTarget: true,
  showInfo: true,
  routeID: '',
  type: '',
  placeholder: 'Select...',
  dropdownStyles: {},
  dropdownTitleStyles: {}
};
const mapStateToProps = state => ({
  dashboardType: state.layoutStore.routes?.dashboardConfig?.version
});

export default connect(
  mapStateToProps,
  null
)(withStyles(dropDownStyles)(Dropdown));
