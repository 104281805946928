import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { formatDate, getPreviousDate } from 'lib/core/dateUtils';

// store selectors
const getStoreExplorerStore = state => state.storeExplorer;

const getImageFetchingStatus = createSelector(
  [getStoreExplorerStore],
  ({ images: { isFetching } }) => isFetching
);

const getReportData = createSelector(
  [getStoreExplorerStore],
  ({ reportData }) => reportData
);

const getShelfImages = createSelector(
  [getStoreExplorerStore],
  ({
    sessionId,
    excelReport,
    images,
    variants,
    stickerBoxes,
    store_explorer_settings,
    session_data
  }) => {
    if (!isEmpty(images.list) && !isEmpty(variants.list)) {
      return {
        sessionId,
        excelReport,
        stickerBoxes: stickerBoxes.list,
        images: images.list,
        variants: variants.list,
        store_explorer_settings,
        session_data: session_data || []
      };
    }
    return {
      excelReport: false,
      imageList: null,
      variantList: null
    };
  }
);

const getFilterData = createSelector(
  getStoreExplorerStore,
  ({ filters }) => filters
);

const getFilterPreferences = (state, props) =>
  state.storeExplorer.preferences.filters[props.routeID];

const makeGetFilterPreferences = () =>
  createSelector([getFilterPreferences], prefs => prefs);

const getFilterList = state => state.storeExplorer.filters.list;

const makeGetFilterList = () =>
  createSelector([getFilterList], data => {
    const dataClone = { ...data.fl_date };
    if (dataClone) {
      dataClone.options = [
        {
          label: formatDate(getPreviousDate(0, 'days', false), true),
          value: 'today',
          is_default: true
        },
        { label: 'Custom', value: 'custom', args: 'cus' }
      ];
    }
    return { ...data, fl_date: dataClone };
  });

const makeGetParamsValueList = () =>
  createSelector([getFilterPreferences], filterPrefs => {
    return {
      ...filterPrefs
    };
  });

const isFetchingSessionDataSuccessful = createSelector(
  getStoreExplorerStore,
  ({ apiRequestStatus }) => apiRequestStatus.sessionData.success
);

export {
  getImageFetchingStatus,
  getShelfImages,
  getReportData,
  getFilterData,
  makeGetFilterList,
  makeGetFilterPreferences,
  makeGetParamsValueList,
  isFetchingSessionDataSuccessful
};
